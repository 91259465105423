.drop-active {
    border-radius: 4px;
    border: solid 1px rgba(211, 207, 194, 0.49);
    background-color: #ffffff;
    min-height: 89px;
    .upload-label-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .img-wrap {
            height: 30px;
            margin-bottom: 15px;
        }
        label {
            color: #9b9b9b !important;
            cursor: pointer;
            text-align: center;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 500;
        }
        p {
            font-weight: 300;
        }
        .color-error {
            color: #ff7f7e !important;
            font-style: italic;
            margin-bottom: 15px;
        }
    }
    .loading-gif {
        margin-top: 17px;
        margin-left: auto;
        margin-right: auto;
    }
}